@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: RobotoBlack;
    src: url("/public/Fonts/Roboto-Black.ttf");
}

@font-face {
    font-family: RobotoBold;
    src: url("/public/Fonts/Roboto-Bold.ttf");
}

@font-face {
    font-family: RobotoLight;
    src: url("/public/Fonts/Roboto-Light.ttf");
}

@font-face {
    font-family: RobotoRegular;
    src: url("/public/Fonts/Roboto-Regular.ttf");
}

html, body {
  font-family: RobotoRegular, sans-serif;
  font-style: normal;
  font-weight: 300;
}

.roboto-black{
    font-family: RobotoBlack, sans-serif;
}

.roboto-bold{
    font-family: RobotoBold, sans-serif;
}

.roboto-light{
    font-family: RobotoLight, sans-serif;
}

ol li::marker {
    font-weight: 700;
    padding-right: 5px;
}

.react-multi-carousel-item{
    padding-right: 15px;
}
.react-multi-carousel-item:last-of-type{
    padding-right: 0;
}

.box-left {
    width: 50%;
    height: 50%;
    position: absolute;
    bottom: -7%;
    left: -4%;
    z-index: 1;
    opacity: 0.8;
}

.box-right {
    width: 50%;
    height: 50%;
    position: absolute;
    top: -7%;
    right: -4%;
    z-index: 1;
    opacity: 0.8;
}

@media only screen and (max-width: 768px) {
    .box-left  {
        left: -1.25rem;
    }
    .box-right  {
        right: -1.25rem;
    }
}

.orange {
    background: #DB3222;
}

.purple {
    background: #702963;
}
.yellow {
    background: #FFBF00;
}
.blue {
    background: #89CFF0;
}

.red {
    background: #C82121;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #C82121 !important;
}
.MuiOutlinedInput-notchedOutline {
    border-color: #C82121 !important;
}

.MuiPopover-root {
    margin-top: 5px;
}
